body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "ae_Sindibad";
  src: local("ae_Sindibad"), url(./Fonts/ae_Sindibad.ttf) format("truetype");
}

@font-face {
  font-family: "Arkibal Regular";
  src: local("Arkibal Regular"),
    url(./Fonts/Arkibal\ Display\ Regular_mg.ttf) format("truetype");
}
@font-face {
  font-family: "Arkibal Regular Italic";
  src: local("Arkibal Regular Italic"),
    url(./Fonts/Arkibal\ Display\ Regular\ Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Arkibal Light";
  src: local("Arkibal Light"),
    url(./Fonts/Arkibal\ Display\ Light.ttf) format("truetype");
}
